import React, { useCallback, useEffect, useState } from 'react';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { SEND_CONFIRM } from '../../const/api.const';
import { POST_JSON_RESPONSE_DEFAULT_FORMAT } from '../../const/requests.const';
import { USER_EMAIL_STORAGE_NAME } from '../../const/storage.const';
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader';
import { useMessage } from '../../hooks/useMessage.hook';
import { DOWNLOADS_ROUTE } from '../../const/routes.const';
import Box from '../common/layout/Box';
import * as styles from './RegSuccess.module.scss';

const MESSAGE_ERROR_KEY = 'errorMessageKey';
const MESSAGE_SUCCESS_KEY = 'successMessageKey';

const RegSuccess = (): React.ReactElement => {
  const [email, setEmail] = useState<string>('');
  const { t } = useTranslation();
  const message = useMessage();

  useEffect(() => {
    setTimeout(() => {
      const emailFromStore = localStorage.getItem(USER_EMAIL_STORAGE_NAME);

      if (emailFromStore) {
        setEmail(emailFromStore);
      }
    }, 1000);

    return () => {
      message.destroy(MESSAGE_ERROR_KEY);
      message.destroy(MESSAGE_SUCCESS_KEY);
    };
  }, []);

  const error = () => {
    message
      .error({
        content: t('Something went wrong. Try again later.'),
        duration: 5,
        className: 'errorMessage',
        key: MESSAGE_ERROR_KEY,
        onClick: () => message.destroy(MESSAGE_ERROR_KEY),
      })
      .then();
  };

  const success = () => {
    message
      .success({
        content: t('A verification link was sent'),
        duration: 5,
        key: MESSAGE_SUCCESS_KEY,
        onClick: () => message.destroy(MESSAGE_SUCCESS_KEY),
      })
      .then();
  };

  const handleResend = useCallback(() => {
    message.destroy(MESSAGE_ERROR_KEY);
    message.destroy(MESSAGE_SUCCESS_KEY);

    fetch(`${SEND_CONFIRM}`, {
      ...POST_JSON_RESPONSE_DEFAULT_FORMAT,
      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          success();
        } else {
          error();
        }
      })
      .catch(() => error());
  }, [email]);

  return (
    <div className={styles.wrapper}>
      {email ? (
        <div className={styles.wrapperInner}>
          <h1 className={styles.title}>
            <Trans>Check your inbox</Trans>
          </h1>
          <div className={styles.text}>{t('signup.success.message', { email })}</div>
          <div className={styles.steps}>
            <div className={styles.step}>
              <span className={styles.num}>1</span>
              <Box p={2} />
              <Trans>Click the link to verify your email and confirm the registration</Trans>
            </div>
            <div className={styles.step}>
              <span className={styles.num}>2</span>
              <Box p={2} />
              <span>
                <Trans i18nKey="Download and install Octo Browser client">
                  Download and install <Link to={DOWNLOADS_ROUTE}>Octo Browser client</Link>
                </Trans>
              </span>
            </div>
            <div className={styles.step}>
              <span className={styles.num}>3</span>
              <Box p={2} />
              <Trans>Log in to the browser using your email and password</Trans>
            </div>
          </div>
          <div className={styles.text}>
            <Trans>You may need to check your spam folder.</Trans>
            <br />
            <Trans>Don't see it?</Trans>{' '}
            <span className={styles.link} onClick={handleResend}>
              <Trans>Resend link</Trans>
            </span>
          </div>
        </div>
      ) : (
        <FullScreenLoader />
      )}
    </div>
  );
};

export default RegSuccess;
