import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts';
import RegSuccess from '../components/RegSuccess/RegSuccess';
import RobotsNoIndex from '../components/RobotsNoIndex/RobotsNoIndex';

const RegSuccessPage = (): React.ReactElement => {
  return (
    <Layout>
      <RegSuccess />
    </Layout>
  );
};

export default RegSuccessPage;

export function Head() {
  return <RobotsNoIndex />;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["translation", "regSuccessPagePage"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
