// extracted by mini-css-extract-plugin
export var antBtnLg = "RegSuccess-module--ant-btn-lg--8ZD-q";
export var antBtnPrimary = "RegSuccess-module--ant-btn-primary--5Rc9L";
export var antFormItem = "RegSuccess-module--ant-form-item--W6viB";
export var antFormItemLabel = "RegSuccess-module--ant-form-item-label--jQYvm";
export var antInput = "RegSuccess-module--ant-input--021Mu";
export var antMessageNotice = "RegSuccess-module--ant-message-notice--H+yq0";
export var antMessageNoticeContent = "RegSuccess-module--ant-message-notice-content--VAqB7";
export var errorMessage = "RegSuccess-module--errorMessage--bZPwq";
export var link = "RegSuccess-module--link--sPJEc";
export var num = "RegSuccess-module--num--oc3P4";
export var step = "RegSuccess-module--step--2MAfk";
export var steps = "RegSuccess-module--steps--xAFuv";
export var text = "RegSuccess-module--text--ibPS8";
export var title = "RegSuccess-module--title--4CbbE";
export var wrapper = "RegSuccess-module--wrapper--te59M";
export var wrapperInner = "RegSuccess-module--wrapperInner--CDqzH";